import React, { Component } from 'react';

import Heading2 from '../../Atoms/Heading/Heading2';

import './index.scss';
import Map from '../../Molecules/Map';

class Location extends Component {
  render() {
    return (
      <section className="panel">
        <div className="location">
          <div className="location-item">
            <Heading2>Localização</Heading2>

            <p>
              Rua Heróis de França, 617<br/>
              Matosinhos, Porto 4450-159
            </p>

            <div className="second-heading">
              <Heading2>Horários</Heading2>
            </div>

            <p>
              Segunda <br/>
              Fechado
            </p>

            <p>
              Terça a Sexta <br/>
              16h - 22h
            </p>

            <p>
              Sábado e Domingo<br/>
              15h - 22h
            </p>
          </div>

          <div className="location-item location-map">
            <Map />
          </div>
        </div>
      </section>
    );
  }
}

export default Location;
