import React, { Component } from 'react';

import Section from '../../Molecules/Section';

import Delivery from '../../../assets/images/sections/delivery.jpeg';
import PetFriendly from '../../../assets/images/sections/pet_friendly.png';

class Sections extends Component {
  render() {
    return (
      <section className="panel">
        <div className="sections">
          <Section title="Delivery" image={ Delivery }>
            <p> Nesse tempo de pandemia todos temos que ser reponsáveis e ficar em casa.  Mas há coisas que podemos continuar a fazer, como beber uma boa cerveja. </p>
            <p> Pensando nisso a HopTrip lançou o serviço de delivery! </p>
            <p> Confira as nossas opções de cervejas e snacks, e aproveitem os nossos combos. </p>
            <p>
              Na primeira compra o growler tem o valor de 3€, podendo ser reutilizado para as próximas compras sem custo
              adicional.
            </p>
          </Section>

          <Section title="Pet Friendly" image={ PetFriendly }>
            <p>
              Na HopTrip a nossa responsável por receber os pets é a nossa adorável Spuma Puro Malte.
              Os seus animais de estimação são bem-vindos aqui.
            </p>
            <p>Porque os melhores amigos podem vir juntos à HopTrip! </p>
          </Section>
        </div>
      </section>
    );
  }
}

export default Sections;
