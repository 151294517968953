import React, { Component, Dispatch } from 'react';

import Header from '../../components/Molecules/Header';
import Footer from '../../components/Molecules/Footer';
import Parallax from '../../components/Atoms/Parallax';
import About from '../../components/Organisms/About';
import Sections from '../../components/Organisms/Sections';
import Combo from '../../components/Organisms/Combo';
import Location from '../../components/Organisms/Location';

import { connect } from 'react-redux';

import TapImg from '../../assets/images/taps/tap.jpg';

import Parallax1Desktop from "../../assets/images/parallax/parallax1_desktop.png";
import Parallax1Mobile from "../../assets/images/parallax/parallax1_mobile.png";

import Parallax2Desktop from "../../assets/images/parallax/parallax2_desktop.png";
import Parallax2Mobile from "../../assets/images/parallax/parallax2_mobile.png";

import Parallax3Desktop from "../../assets/images/parallax/parallax3_desktop.png";
import Parallax3Mobile from "../../assets/images/parallax/parallax3_desktop.png";

import Parallax4Desktop from "../../assets/images/parallax/parallax4_desktop.png";
import Parallax4Mobile from "../../assets/images/parallax/parallax4_desktop.png";

import Parallax5Desktop from "../../assets/images/parallax/parallax5_desktop.jpg";
import Parallax5Mobile from "../../assets/images/parallax/parallax5_desktop.jpg";

import { State } from '../../store/reducers';
import { listAllStartAction } from '../../store/combos/actions';

import './index.scss';
import ComboDto from '../../dtos/combo.dto';

type Props = {
  combos: ComboDto[],
  requestCombos: () => void,
}

class Home extends Component<Props> {
  componentDidMount() {
    this.props.requestCombos();
  }

  render() {
    return (
      <>
        <Header text="HopTrip"/>
        <Parallax desktopImage={ Parallax1Desktop } mobileImage={ Parallax1Mobile }/>
        <About />
        <Parallax desktopImage={ Parallax2Desktop } mobileImage={ Parallax2Mobile }/>
        <Sections />
        {/* <Parallax desktopImage={ Parallax3Desktop } mobileImage={ Parallax3Mobile }/> */}
        {/* <Combo combos={this.props.combos} title="Combos"/> */}
        {/*<Parallax desktopImage={ Parallax4Desktop } mobileImage={ Parallax4Mobile }/>*/}
        {/*<Combo combos={this.props.taps} title="Taps" image={ TapImg }/>*/}
        <Parallax desktopImage={ Parallax5Desktop } mobileImage={ Parallax5Mobile }/>
        <Location />
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: State) => ({
  combos: state.comboList,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  requestCombos: () => dispatch(listAllStartAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
